import * as React from 'react';
import {GetStaticPropsResult} from 'next';
import Head from 'next/head';
import {useLiveQuery} from 'next-sanity/preview';

import {
  Layout,
  homeSchema,
  getTemplateStaticProps,
  TagFeedFragment,
  Feed,
} from 'shared';

import {GlobalProps, NavData, PageParams} from 'types/types';
import {token} from 'lib/sanity.fetch';
import {globalAndTemplateQuery as query} from '../groq/queries';
import {client} from 'lib/sanity.client';
import {BrowseTemplate} from '@mindfulness/cms';
import {getGlobalAndTemplateData} from '../effects/sanity';
import {defaultNoAuthHomeFeed} from 'shared/utils';

const PageContainer: React.FC<Props> = ({draftMode, ...initialData}) => {
  const [data] = useLiveQuery(initialData, query, {
    slug: initialData.slug,
    type: 'browseTemplate',
  });

  return (
    <>
      {data ? (
        <Layout
          article={null}
          navigation={data.navigation}
          sideNavigation={data.sideNavigation}
          footerNavigation={data.footerNavigation}
          site={data.site}
          draftMode={draftMode}
          client={client as any}
        >
          <Feed feed={initialData.feed} template={data.template} noTitle={true} />
          <Head>
            <script {...homeSchema()} />
            <meta property="og:type" content="website" />
          </Head>
        </Layout>
      ) : null}
    </>
  );
};

/**
 * Query sanity for all the page data we need and provide it to the PageContainer as props
 */
export async function getStaticProps({
  draftMode,
}: PageParams<{}>): Promise<GetStaticPropsResult<Props>> {
  const slug = '/';
  const feedId = defaultNoAuthHomeFeed();
  const data = await getGlobalAndTemplateData<NavData & {
    site: GlobalProps['site'];
    template: BrowseTemplate;
  }>({
    slug,
    type: 'browseTemplate',
    draftMode,
  });


  const feed = await getTemplateStaticProps.Feed(feedId);
  /**
   * Return a 404 if there is no data for the current route
   */

  if (!feed) {
    console.debug('Feed not found');
    return {
      notFound: true,
    };
  }

  return {
    props: {
      draftMode,
      token: draftMode ? token : '',
      feed,
      slug,
      ...data,
    },
  };
}

interface Props extends GlobalProps {
  feed: {
    tagFeed: TagFeedFragment;
    relatedFeeds: TagFeedFragment[];
  };
  template: BrowseTemplate;
}

export default PageContainer;
