import {groq} from 'next-sanity';
import {
  articleFragment,
  articlesFragment,
  footerNavigationFragment,
  globalSettingsFragment,
  hubFragment,
  mobileArticleFragment,
  navigationFragment,
  pageFragment,
  sideNavigationFragment,
  templateFragment,
} from './fragments';

export const pageQuery = groq`${pageFragment}`;

export const redirectQuery = groq`*[_type == "redirects" && source == $slug][0]`;

export const pageTopicQuery = groq`*[_type == "pages" && slug.current == $slug] | order(_updatedAt desc)[0]{
  "topicId": topic.id
}
`;

export const articleQuery = groq`${articleFragment}`;
export const mobileArticleQuery = groq`${mobileArticleFragment}`;

export const articlesQuery = `${articlesFragment}`;

export const typeQuery = groq`*[slug.current == $slug][0]{
  _type
}`;

/**
 * Get the slugs of all documents that are of type _type and not the homepage
 */
export const slugQuery = groq`*[_type == $type && defined(slug.current) && slug.current != "/"]`;

//
export const articlesSlugQuery = groq`*[_type == "articles" && defined(slug.current) && slug.current match "/articles"]`;

/** Get the slug of any page (other than the homepage) or article and return the slug */
export const childSlugQuery = groq`*[defined(slug.current) && slug.current != "/" && slug.current match "/*/*" && !(slug.current match "/get-started/*") && !(slug.current match "/test/*") && !(slug.current match "/variants/*")]{
  "handle": slug.current,
}`;

export const hubSlugQuery = groq`*[_type == "pages" && defined(slug.current) && type == "hub"]{
  slug,
  topic,
  feed,
}`;
export const landerSlugQuery = groq`*[_type == "pages" && defined(slug.current) && type == "lander"]{
  slug
}`;

export const sideNavigationQuery = groq`${sideNavigationFragment}`;

export const navigationQuery = groq`${navigationFragment}`;
export const footerNavigationQuery = groq`${footerNavigationFragment}`;

export const templateQuery = groq`${templateFragment}`;

export const teacherQuery = groq`*[_type == "teachers" && teacher.id == $id][0]`;

export const siteSettingsQuery = globalSettingsFragment;

export const combinedPageQuery = `{
  "site": ${globalSettingsFragment},
  "page": ${pageFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment}
}`;

export const mixedQuery = `{
  "site": ${globalSettingsFragment},
  "page": ${pageFragment},
  "article": ${articleFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment}
}`;

export const globalAndTemplateQuery = `{
  "site": ${globalSettingsFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment},
  "template": ${templateQuery}
}`;

export const hubQuery = `
{
  "site": ${globalSettingsFragment},
  "hub": ${hubFragment},
  "page": ${pageFragment},
  "article": ${articleFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment},
  "template": ${templateQuery}
}
`;

export const articleHubQuery = `
{
  "site": ${globalSettingsFragment},
  "hub": ${hubFragment},
  "page": ${pageFragment},
  "article": ${articleFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment}
}
`;

export const articlesHubQuery = `
{
  "site": ${globalSettingsFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment},
  "template": ${templateQuery},
  "articles": ${articlesQuery},
  "articleCount": count(*[_type == "articles"])
}
`;

export const browseQuery = `
{
  "site": ${globalSettingsFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment},
  "template": ${templateQuery},
  "hub": ${pageFragment}
}
`;

export const onboardingQuery = `
{
  "site": ${globalSettingsFragment},
  "page": ${pageFragment},
  "navigation": ${navigationFragment},
  "sideNavigation": ${sideNavigationFragment},
  "footerNavigation": ${footerNavigationFragment}
}
`;
